<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>

    <div class="enterprise clearfix">
      <div class="left">
        <ziyuannavleft />
      </div>
      <div class="right">
        <dl class="project_toptit">
          <dt>招商进度统计</dt>
          <dd>
            <span @click="clickwhole(1)" :class="whole == 1 ? 'hover' : ''"
              >机构内部统计</span
            >
            <span @click="clickwhole(2)" :class="whole == 2 ? 'hover' : ''"
              >统筹机构统计</span
            >
          </dd>
        </dl>
        <ul class="ipt" v-if="whole == 1">
          <!-- 始 -->
          <li>
            年份选择：
            <el-select
              class="iipp"
              v-model="valueyear"
              placeholder="请选择"
              @change="labelchangeyear($event, years, 1)"
            >
              <el-option
                v-for="item in years"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
            <el-select
              class="iipp"
              v-model="valuequarter"
              placeholder="请选择"
              @change="labelchangequarter($event, optionsquarter, 2)"
            >
              <el-option
                v-for="(item, index) in optionsquarter"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>

            <el-select
              class="iipp"
              v-model="valuetypemonth"
              placeholder="请选择"
              @change="labelchangemonths($event, months, 3)"
            >
              <el-option
                v-for="(item, index) in months"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option> </el-select
            >-
            <!-- 终 -->
            <el-select
              class="iipp"
              v-model="valueyearend"
              placeholder="请选择"
              @change="labelchangeyearend($event, yearsend, 1)"
            >
              <el-option
                v-for="(item, index) in yearsend"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
            <el-select
              class="iipp"
              v-model="valuetypeuarter2"
              placeholder="请选择"
              @change="labelchangequarter2($event, quarter2, 1)"
            >
              <el-option
                v-for="(item, index) in quarter2"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              class="iipp"
              v-model="valuemonths"
              placeholder="请选择"
              @change="labelchangemonths2($event, months2, 1)"
            >
              <el-option
                v-for="(item, index) in months2"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </li>
          <li>
            部门选择：
            <el-select
              class="iipp"
              v-model="valuestate"
              placeholder="请选择"
              @change="labelchange($event, section, 4)"
            >
              <el-option
                v-for="item in section"
                :key="item.id"
                :label="item.section"
                :value="item.section"
              ></el-option>
            </el-select>
          </li>
          <li>
            成员选择：
            <el-select
              class="iipp"
              v-model="valuepepeo"
              placeholder="请选择"
              @change="labelchange($event, optionspepeo, 6)"
            >
              <el-option
                v-for="item in optionspepeo"
                :key="item.user_id"
                :label="item.name"
                :value="item.user_id"
              ></el-option>
            </el-select>
          </li>
          <li>
            项目进度：
            <el-select
              class="iipp"
              v-model="valuecapital"
              placeholder="请选择"
              @change="labelchange($event, stage, 3)"
            >
              <el-option
                v-for="(item, index) in stage"
                :key="index"
                :label="item.name"
                :value="item.stage_id"
              ></el-option>
            </el-select>
          </li>
          <li>
            产业选择：
            <el-select
              class="iipp"
              v-model="valueindustry"
              placeholder="请选择"
              @change="labelchange($event, industry, 1)"
            >
              <el-option
                v-for="item in industry"
                :key="item.industry_id"
                :label="item.name"
                :value="item.industry_id"
              ></el-option>
            </el-select>
          </li>
          <li>
            <span class="seach" @click="querycondition">搜索</span>
          </li>
        </ul>
        <ul class="ipt" v-if="whole == 2">
          <!-- 始 -->
          <li>
            年份选择：
            <el-select
              class="iipp"
              v-model="valueyear"
              placeholder="请选择"
              @change="labelchangeyear($event, years, 1)"
            >
              <el-option
                v-for="item in years"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              class="iipp"
              v-model="valuequarter"
              placeholder="请选择"
              @change="labelchangequarter($event, optionsquarter, 2)"
            >
              <el-option
                v-for="(item, index) in optionsquarter"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-select
              class="iipp"
              v-model="valuetypemonth"
              placeholder="请选择"
              @change="labelchangemonths($event, months, 3)"
            >
              <el-option
                v-for="(item, index) in months"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option> </el-select
            >-
            <!-- 终 -->
            <el-select
              class="iipp"
              v-model="valueyearend"
              placeholder="请选择"
              @change="labelchangeyearend($event, yearsend, 1)"
            >
              <el-option
                v-for="(item, index) in yearsend"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              class="iipp"
              v-model="valuetypeuarter2"
              placeholder="请选择"
              @change="labelchangequarter2($event, quarter2, 1)"
            >
              <el-option
                v-for="(item, index) in quarter2"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              class="iipp"
              v-model="valuemonths"
              placeholder="请选择"
              @change="labelchangemonths2($event, months2, 1)"
            >
              <el-option
                v-for="(item, index) in months2"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            机构选择：
            <el-select
              class="iipp"
              v-model="valueaptitude"
              placeholder="请选择"
              @change="labelchange($event, optionsaptitude, 'jigou')"
            >
              <el-option
                v-for="item in optionsaptitude"
                :key="item.unit_id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </li>
          <li>
            项目进度：
            <el-select
              class="iipp"
              v-model="valuecapital"
              placeholder="请选择"
              @change="labelchange($event, stage, 3)"
            >
              <el-option
                v-for="(item, index) in stage"
                :key="index"
                :label="item.name"
                :value="item.stage_id"
              ></el-option>
            </el-select>
          </li>
          <li>
            产业选择：
            <el-select
              class="iipp"
              v-model="valueindustry"
              placeholder="请选择"
              @change="labelchange($event, industry, 1)"
            >
              <el-option
                v-for="item in industry"
                :key="item.industry_id"
                :label="item.name"
                :value="item.industry_id"
              ></el-option>
            </el-select>
          </li>
          <li>
            <span class="seach" @click="querycondition">搜索</span>
          </li>
        </ul>
        <div class="project_main">
          <dl class="project_tit">
            <dt>
              <span :class="cartogram == 1 ? 'hover' : ''" @click="changeCar"
                >统计图</span
              >
              <span
                :class="cartogram == 2 ? 'hover' : ''"
                @click="cartogram = 2"
                >统计表</span
              >
            </dt>
            <dd v-if="cartogram == 2" @click="getdownItemFile(1)">
              <a class="chengse_kong">表格下载</a>
            </dd>
          </dl>
          <div class="tongj_box" v-if="cartogram == 1">
            <h1>按招商行业进行统计</h1>
            <div id="main0" style="width: 100%; height: 300px"></div>
          </div>
          <div class="tongj_box" v-if="cartogram == 1">
            <h1>按项目阶段进行统计</h1>
            <div id="main1" style="width: 100%; height: 300px"></div>
          </div>

          <div class="project_tables" v-if="cartogram == 2">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr v-for="(item, index) in stagelist" :key="index">
                <td style="width: 180px">{{ item[0] }}</td>
                <td style="width: 180px">{{ item[1] }}</td>
                <td style="width: 180px">{{ item[2] }}</td>
                <td style="width: 180px">{{ item[3] }}</td>
                <td style="width: 180px">{{ item[4] }}</td>
                <td style="width: 180px">{{ item[5] }}</td>
                <td style="width: 180px">{{ item[6] }}</td>
                <td style="width: 180px">{{ item[7] }}</td>
                <td style="width: 180px">{{ item[8] }}</td>
                <td style="width: 180px">{{ item[9] }}</td>
                <td style="width: 180px">{{ item[10] }}</td>
                <td style="width: 180px">{{ item[11] }}</td>
                <td style="width: 180px">{{ item[12] }}</td>
                <td style="width: 180px">{{ item[13] }}</td>
              </tr>
            </table>
          </div>

          <dl class="project_tit" v-if="cartogram == 2">
            <dd @click="getdownItemFile(2)">
              <a class="chengse_kong">表格下载</a>
            </dd>
          </dl>
          <div class="tongj_box" v-if="cartogram == 1 && whole == 1">
            <!-- <h1>按招商机构进行统计</h1> -->
            <h1>按招商部室统计</h1>
            <div id="main3" style="width: 100%; height: 300px"></div>
          </div>
          <div class="tongj_box" v-if="cartogram == 1 && whole == 1">
            <!-- <h1>按招商机构进行统计</h1> -->
            <h1>按招商人员进行统计</h1>
            <div id="main2" style="width: 100%; height: 300px"></div>
          </div>
          <div class="tongj_box" v-if="cartogram == 1 && whole == 2">
            <h1>按招商人员进行统计</h1>
            <!-- <h1>按招商人员进行统计</h1> -->
            <div id="main2" style="width: 100%; height: 300px"></div>
          </div>
          <div class="tongj_box" v-if="cartogram == 1 && whole == 2">
            <h1>按招商机构进行统计</h1>
            <!-- <h1>按招商人员进行统计</h1> -->
            <div id="main2" style="width: 100%; height: 300px"></div>
          </div>

          <div class="project_tables" v-if="cartogram == 2">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr v-for="(item, index) in industrylist" :key="index">
                <td style="width: 9%">{{ item[0] }}</td>
                <td style="width: 9%">{{ item[1] }}</td>
                <td style="width: 9%">{{ item[2] }}</td>
                <td style="width: 9%">{{ item[3] }}</td>
                <td style="width: 9%">{{ item[4] }}</td>
                <td style="width: 9%">{{ item[5] }}</td>
                <td style="width: 9%">{{ item[6] }}</td>
                <td style="width: 9%">{{ item[7] }}</td>
                <td style="width: 9%">{{ item[8] }}</td>
              </tr>
            </table>
          </div>

          <dl class="project_tit" v-if="cartogram == 2">
            <dd @click="getdownItemFile(3)">
              <a class="chengse_kong">表格下载</a>
            </dd>
          </dl>

          <!-- <div class="tongj_box" v-if="cartogram == 1">
            <h1>按招商行业进行统计</h1>
            <div id="main0" style="width: 100%; height: 300px"></div>
          </div> -->

          <div class="project_tables" v-if="cartogram == 2">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr v-for="(item, index) in userlist" :key="index">
                <td style="width: 10%">{{ item[0] }}</td>
                <td style="width: 10%">{{ item[1] }}</td>
                <td style="width: 10%">{{ item[2] }}</td>
                <td style="width: 10%">{{ item[3] }}</td>
                <td style="width: 10%">{{ item[4] }}</td>
                <td style="width: 10%">{{ item[5] }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts/lib/echarts";
import {
  UnitUnit,
  listAddItem,
  getSectionShare,
  getSec,
  getSecpeople,
  stageInTable,
  stageInList,
  stageOutTable,
  stageOutList,
  stageInDown,
  stageOutDown,
} from "@/api/index";
import "echarts/lib/component/graphic";
export default {
  data() {
    return {
      tagnum: 7,
      whole: 1,
      // valueyear: "2019年",
      valueyear: "",
      optionyear: [""],
      valueindustry: "",
      valuetype: "",
      optionstype: "",
      options: [],
      optionsstate: "",
      valuestate: "",
      valuepepeo: "",
      optionsstate: "",
      valueaptitude: "",
      optionsaptitude: [],
      valuecapital: "",
      optionscapital: "",
      years: [],
      months: [],
      yearsend: [
        { value: "2019年", label: "2020年" },
        { value: "2019年", label: "2019年" },
      ],
      months: [],
      // valueyearend: "2020年",
      valueyearend: "",
      // valuetypemonth: "2月",
      valuetypemonth: "",
      optionsquarter: [
        { value: 0, label: "第一季度" },
        { value: 1, label: "第二季度" },
        { value: 2, label: "第三季度" },
        { value: 3, label: "第四季度" },
      ],
      // valuetypeuarter2: "第四季度",
      valuetypeuarter2: "",
      // valuemonths: "11月",
      valuemonths: "",
      months2: [
        { value: "10月", label: "10月" },
        { value: "11月", label: "11月" },
        { value: "12月", label: "12月" },
      ],
      quarter2: [
        { value: 0, label: "第一季度" },
        { value: 1, label: "第二季度" },
        { value: 2, label: "第三季度" },
        { value: 3, label: "第四季度" },
      ],
      // valuequarter: "第一季度",
      valuequarter: "",
      cartogram: 1,
      stage: [], // 进度
      stageid: "", // 进度id
      industryid: "", // 能源
      industry: [], // 能源
      section: [], // 部门
      sectionid: "", // 部门
      optionspepeo: [], //人员
      peopleid: "",
      industrylist: [],
      stagelist: [],
      userlist: [],
      unitid: "",
    };
  },
  mounted() {},
  created() {
    this.init();
    this.getUnitUnit();
    this.getlistAddItem();
    this.getSec();
    this.getstageInTable();
    this.getstageInList();
    // this.getstageOutTable();
  },
  methods: {
    changeCar() {
      this.cartogram = 1;
      if (this.whole == 1) {
        this.getstageInTable();
      } else if (this.whole == 2) {
        this.getstageOutTable();
      }
    },
    // 年季度月选择
    init() {
      var myDate = new Date();
      var year = myDate.getFullYear(); //获取当前年
      var month = myDate.getMonth() + 1; //获取当前月
      this.initSelectYear(year);
      this.initSelectMonth();
      this.yearsModel = year;
      this.monthsModel = month;
      let obj = {
        year: this.yearsModel,
        month: this.monthsModel,
      };
    },
    initSelectYear(year) {
      this.years = [];
      this.years.push({ value: "", label: "请选择" });
      for (let i = 0; i < year - 2018; i++) {
        this.years.push({ value: year - i + "年", label: year - i + "年" });
      }
    },
    initSelectMonth() {
      this.months = [];
      this.months.push();
      for (let i = 1; i <= 12; i++) {
        this.months.push({ value: i + "月", label: i + "月" });
      }
    },
    labelchangeyear(e, obj, num) {
      console.log(obj);
      this.valuetypeuarter2 = "";
      this.valuetypemonth = "";
      this.valuequarter = "";
      this.valuemonths = "";
      this.valueyearend = "";
      if (e === "请选择") {
        this.valueyear = "";
        return false;
      }
      console.log(obj);
      var myDate = new Date();
      var year = myDate.getFullYear(); //获取当前年
      this.yearsend = [];
      for (let i = 0; i < year - e.split("年", 4)[0] + 1; i++) {
        this.yearsend.push({ value: year - i, label: year - i + "年" });
      }
    },
    labelchangequarter(e, obj, num) {
      console.log(e, obj, num, 22222222);
      this.valuetypeuarter2 = "";
      this.valuetypemonth = "";
      this.valuemonths = "";
      switch (e) {
        case "第一季度":
          this.months = [
            { value: 1, label: "1月" },
            { value: 2, label: "2月" },
            { value: 3, label: "3月" },
          ];
          this.quarter2 = [
            { value: 0, label: "第一季度" },
            { value: 1, label: "第二季度" },
            { value: 2, label: "第三季度" },
            { value: 3, label: "第四季度" },
          ];
          break;
        case "第二季度":
          this.months = [
            { value: 4, label: "4月" },
            { value: 5, label: "5月" },
            { value: 6, label: "6月" },
          ];
          this.quarter2 = [
            { value: 1, label: "第二季度" },
            { value: 2, label: "第三季度" },
            { value: 3, label: "第四季度" },
          ];
          break;
        case "第三季度":
          this.months = [
            { value: 7, label: "7月" },
            { value: 8, label: "8月" },
            { value: 9, label: "9月" },
          ];
          this.quarter2 = [
            { value: 2, label: "第三季度" },
            { value: 3, label: "第四季度" },
          ];
          break;
        case "第四季度":
          this.months = [
            { value: 10, label: "10月" },
            { value: 11, label: "11月" },
            { value: 12, label: "12月" },
          ];
          this.quarter2 = [{ value: 3, label: "第四季度" }];
          break;
      }
    },
    labelchangequarter2(e, obj, num) {
      console.log(e, obj, 1111111111);
      switch (e) {
        case 0:
          if (
            this.valueyear == this.valueyearend &&
            this.valuequarter == this.valuetypeuarter2
          ) {
            if (this.valuetypemonth == "1月") {
              this.months2 = [
                { value: 1, label: "1月" },
                { value: 2, label: "2月" },
                { value: 3, label: "3月" },
              ];
            }
            if (this.valuetypemonth == "2月") {
              this.months2 = [
                { value: 2, label: "2月" },
                { value: 3, label: "3月" },
              ];
            }
            if (this.valuetypemonth == "3月") {
              this.months2 = [{ value: 3, label: "3月" }];
            }
          } else {
            this.months2 = [
              { value: 1, label: "1月" },
              { value: 2, label: "2月" },
              { value: 3, label: "3月" },
            ];
          }

          break;
        case 1:
          if (
            this.valueyear == this.valueyearend &&
            this.valuequarter == this.valuetypeuarter2
          ) {
            if (this.valuetypemonth == "4月") {
              this.months2 = [
                { value: 4, label: "4月" },
                { value: 5, label: "5月" },
                { value: 6, label: "6月" },
              ];
            }
            if (this.valuetypemonth == "5月") {
              this.months2 = [
                { value: 5, label: "5月" },
                { value: 6, label: "6月" },
              ];
            }
            if (this.valuetypemonth == "6月") {
              this.months2 = [{ value: 6, label: "6月" }];
            }
          } else {
            this.months2 = [
              { value: 4, label: "4月" },
              { value: 5, label: "5月" },
              { value: 6, label: "6月" },
            ];
          }

          break;
        case 2:
          if (
            this.valueyear == this.valueyearend &&
            this.valuequarter == this.valuetypeuarter2
          ) {
            if (this.valuetypemonth == "7月") {
              this.months2 = [
                { value: 7, label: "7月" },
                { value: 8, label: "8月" },
                { value: 9, label: "9月" },
              ];
            }
            if (this.valuetypemonth == "8月") {
              this.months2 = [
                { value: 8, label: "8月" },
                { value: 9, label: "9月" },
              ];
            }
            if (this.valuetypemonth == "9月") {
              this.months2 = [{ value: 9, label: "9月" }];
            }
          } else {
            this.months2 = [
              { value: 7, label: "7月" },
              { value: 8, label: "8月" },
              { value: 9, label: "9月" },
            ];
          }

          break;
        case 3:
          if (
            this.valuetype == this.valueyearend &&
            this.valuequarter == this.valuetypeuarter2
          ) {
            if (this.valuetypemonth == "10月") {
              this.months2 = [
                { value: 10, label: "10月" },
                { value: 11, label: "11月" },
                { value: 12, label: "12月" },
              ];
            }
            if (this.valuetypemonth == "11月") {
              this.months2 = [
                { value: 11, label: "11月" },
                { value: 12, label: "12月" },
              ];
            }
            if (this.valuetypemonth == "12月") {
              this.months2 = [{ value: 12, label: "12月" }];
            }
          } else {
            this.months2 = [
              { value: 10, label: "10月" },
              { value: 11, label: "11月" },
              { value: 12, label: "12月" },
            ];
          }

          break;
      }
    },
    labelchangemonths(e, obj, num) {
      this.valuetypeuarter2 = "";
      this.valuemonths = "";
    },
    labelchangeyearend(e, obj, num) {},
    labelchangemonths2(e, obj, num) {},
    // 年季度月选择 end
    // 点击转换 回复所有下拉选项
    clickwhole(num) {
      this.whole = num;
      // this.valuetypeuarter2 = "第四季度";
      // this.valuetypemonth = "2月";
      // this.valuequarter = "第一季度";
      // this.valuemonths = "11月";
      // this.valueyearend = "2020年";
      // this.valueyear = "2019年";
      this.valueyear = "";
      this.valuetypemonth = "";
      this.valueyearend = "";
      this.valuemonths = "";
      this.stageid = "";
      this.industryid = "";
      this.valuestate = "";
      this.peopleid = "";
      this.unitid = "";
      // this.valuetypeuarter2 = "";
      // this.valuetypemonth = "";
      // this.valuequarter = "";
      // this.valuemonths = "";
      // this.valueyearend = "";
      this.valueyear = "";
      this.valuestate = "";
      this.valuepepeo = "";
      this.valuecapital = "";
      this.valueindustry = "";
      this.valueaptitude = "";
      this.valuecapital = "";
      if (num == 1) {
        this.getstageInTable();
        this.getstageInList();
      } else {
        this.getstageOutTable();
        this.getstageOutList();
      }
    },
    // 机构内部统计接口数据
    async getstageInTable() {
      const res = await stageInTable({
        year_begin: this.valueyear,
        month_begin: this.valuetypemonth,
        year_end: this.valueyearend,
        month_end: this.valuemonths,
        stage_id: this.stageid,
        indu_id: this.industryid,
        section: this.valuestate,
        user_id: this.peopleid,
      });
      this.ceshi(res);
    },
    async getstageInList() {
      const res = await stageInList({
        year_begin: this.valueyear,
        month_begin: this.valuetypemonth,
        year_end: this.valueyearend,
        month_end: this.valuemonths,
        stage_id: this.stageid,
        indu_id: this.industryid,
        section: this.valuestate,
        user_id: this.peopleid,
      });
      this.industrylist = res[0].industry;
      this.stagelist = res[0].stage;
      this.userlist = res[0].user;
    },
    // 统筹机构统计
    async getstageOutTable() {
      const resx = await stageOutTable({
        year_begin: this.valueyear,
        month_begin: this.valuetypemonth,
        year_end: this.valueyearend,
        month_end: this.valuemonths,
        stage_id: this.stageid,
        indu_id: this.industryid,
        unit: this.unitid,
      });

      this.ceshi(resx);
    },
    async getstageOutList() {
      const resx = await stageOutList({
        year_begin: this.valueyear,
        month_begin: this.valuetypemonth,
        year_end: this.valueyearend,
        month_end: this.valuemonths,
        stage_id: this.stageid,
        indu_id: this.industryid,
        unit: this.unitid,
      });
      this.industrylist = resx[0].industry;
      this.stagelist = resx[0].stage;
      this.userlist = resx[0].unit;
    },
    // 下载
    async getdownItemFile(id) {
      if (this.whole == 1) {
        const res = await stageInDown({
          year_begin: this.valueyear,
          month_begin: this.valuetypemonth,
          year_end: this.valueyearend,
          month_end: this.valuemonths,
          stage_id: this.stageid,
          indu_id: this.industryid,
          section: this.valuestate,
          user_id: this.peopleid,
          type: id,
        });
        this.jqDownload(res.url, res.filename);
      } else {
        const res = await stageInDown({
          year_begin: this.valueyear,
          month_begin: this.valuetypemonth,
          year_end: this.valueyearend,
          month_end: this.valuemonths,
          stage_id: this.stageid,
          indu_id: this.industryid,
          unit: this.unitid,
          type: id,
        });
        this.jqDownload(res.url, res.filename);
      }
    },
    jqDownload(url, name) {
      fetch(url).then((res) =>
        res.blob().then((blob) => {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          var filename = name;
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
    },
    // 图表
    ceshi(res) {
      let arr = [];
      if (this.whole == 1) {
        arr.push(res.industry);
        arr.push(res.stage);
        arr.push(res.user);
        arr.push(res.section);
      } else {
        arr.push(res.industry);
        arr.push(res.stage);
        arr.push(res.unit);
      }
      for (var i = 0; i < arr.length; i++) {
        var xdata = [];
        var seriesdata = [];
        // console.log(arr[i])
        for (var j = 0; j < arr[i].length; j++) {
          xdata.push(arr[i][j].name);
          // console.log(xdata);
          seriesdata.push(arr[i][j].value);
        }
        this.picture(xdata, seriesdata, i);
      }
    },
    picture(xdata, seriesdata, num) {
      const getid = "main" + num;
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(getid));
      // 指定图表的配置项和数据
      let option = {
        color: ["#999"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "8%",
          left: "8%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xdata,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#c1d7eb",
                //坐标轴的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 300,
            splitNumber: 6,
            splitLine: {
              lineStyle: {
                color: "#c1d7eb",
                //坐标轴的颜色
              },
              show: true,
              //去掉背景的网格线
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#A2CAEF",
                //坐标轴的颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "15",
            // data: [30, 82, 900, 334, 390, 330, 220, 330, 220],
            data: seriesdata,
            itemStyle: {
              normal: {
                barBorderRadius: 4,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#3D72FF",
                  },
                  {
                    offset: 0.9,
                    color: "#68B2FF",
                  },
                ]),
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表的。
      myChart.setOption(option);
    },

    //搜索
    querycondition() {
      if (this.whole == 1) {
        console.log(1);
        this.getstageInTable();
        this.getstageInList();
      } else {
        console.log(2);
        this.getstageOutTable();
        this.getstageOutList();
      }
    },
    // 获取下拉数据
    async getUnitUnit() {
      const res = await UnitUnit();
      this.optionsaptitude = res;
    },
    async getlistAddItem() {
      const res = await listAddItem();
      this.industry = res.industry;
      this.stage = res.stage;
    },
    // 获取部门
    async getSec() {
      const res = await getSec();
      this.section = res;
      console.log(this.section);
    },
    async getSecpeople() {
      const res = await getSecpeople({
        section: this.valuestate,
      });
      this.optionspepeo = res;
    },
    labelchange(e, obj, num) {
      switch (num) {
        case 1:
          this.industryid = e;
          break;
        case 3:
          this.stageid = e;
          break;
        case 4:
          this.getSecpeople();
          break;
        case 6:
          this.peopleid = e;
          break;
        case "jigou":
          this.unitid = e;
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  background: rgb(243, 243, 243);

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
.right {
  width: calc(100% - 256px - 12px);
  float: right;

  .project_toptit {
    background: #fff;
    padding: 10px 20px;
    overflow: hidden;
    zoom: 1;

    dt {
      float: left;
      font-size: 16px;
      color: #444;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      margin-right: 20px;
    }
    dd {
      float: left;

      span {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        text-align: center;
        margin: 0 20px;
        cursor: pointer;
      }
      .hover {
        color: #fe6a00;
      }
    }
  }

  .ipt {
    background: #fff;
    padding: 0 30px;
    overflow: hidden;
    zoom: 1;
    margin-bottom: 10px;

    li {
      display: block;
      width: 60%;
      height: 31px;
      float: left;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #333333;
      margin-bottom: 1%;

      /deep/.el-select {
        width: 13%;
        height: 30px;
        margin-right: 5px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
      /deep/.el-input {
        width: 100%;
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          color: #333;
          background: #ffffff;
          border: 1px solid #dddddd;
          padding: 0 5px;
        }
        ::-webkit-input-placeholder {
          color: #333;
        }
        /deep/.el-icon-circle-close {
          background: url("../../assets/merchantsradar/cuo.png") 50% 50%
            no-repeat;
        }
        .el-icon-circle-close:before {
          content: "";
        }
      }
    }

    li:nth-child(2) {
      width: 22.5%;
      /deep/.el-select {
        width: 65%;
        margin: 0;
      }
    }
    li:nth-child(3) {
      width: 17.5%;
      /deep/.el-select {
        width: 55%;
        margin: 0;
      }
    }
    li:nth-child(4) {
      width: 20%;
      /deep/.el-select {
        width: 56%;
      }
    }
    li:nth-child(5) {
      width: 20%;
      /deep/.el-select {
        width: 56%;
      }
    }
    li:nth-child(6) {
      width: 10%;
      /deep/.el-select {
        width: 75%;
      }
    }
  }

  .proje_peitaoul {
    li {
      width: 100%;
      float: none;
      overflow: hidden;
      zoom: 1;

      strong {
        font-weight: normal;
        display: block;
        width: 105px;
      }

      span {
        width: 88%;
      }
      /deep/.el-input {
        width: 100%;
      }
    }
  }

  .proje_fazhanul {
    li {
      width: 100%;
      float: none;
      overflow: hidden;
      zoom: 1;

      strong {
        font-weight: normal;
        display: block;
        width: 125px;
      }

      span {
        width: 88%;
      }
      /deep/.el-input {
        width: 100%;
      }
    }
  }

  .project_main {
    background: #fff;
    padding: 0 20px 10px 20px;
    overflow: hidden;
    zoom: 1;

    .pre_xtit {
      font-size: 14px;
      text-align: left;
      line-height: 28px;
      margin-left: 12px;
      font-weight: normal;
    }
    .pre_xtit::before {
      content: " ";
      display: block;
      float: left;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      margin-top: 10px;
      background: #333;
    }

    .project_area {
      text-align: left;
      font-size: 14px;
      height: 100px;
      line-height: 28px;
      margin-left: 15px;
      margin-bottom: 10px;

      /deep/.el-textarea__inner {
        height: 100px;
        padding: 12px;
      }
      /deep/.el-textarea__inner:focus {
        border-color: #fe6a00;
      }
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;
      margin-bottom: 20px;
      border-bottom: 1px solid #eee;
      height: 500px;
      overflow: auto;
      width: 100%;
      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        color: #fe6a00;
      }
      .lvzi_btn {
        color: #2cb54c;
      }
      .zizi_btn {
        color: #135de6;
      }
      .redzi_btn {
        color: #e51313;
      }
      .bluezi_btn {
        color: #0ebbf3;
      }
      .huangzi_btn {
        color: #e69554;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    .pro_btns {
      display: flex;
      justify-content: center;
    }
    .chengse_btn {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      background: #fe6a00;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      margin: 1% 5px;
      padding: 0 20px;
    }
    .chengse_kong {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      border: 1px solid #fe6a00;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      color: #fe6a00;
      margin: 1% 5px;
      padding: 0 20px;
    }
  }

  .project_tit {
    overflow: hidden;
    zoom: 1;
    margin: 10px 0 6px 0;

    dt {
      float: left;
      font-size: 16px;
      color: #333;
      height: 30px;
      line-height: 30px;
      span {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        text-align: center;
        margin-right: 35px;
        cursor: pointer;
      }
      .hover {
        color: #fe6a00;
        font-weight: bold;
      }
    }

    dd {
      float: right;
      /deep/.el-input {
        width: 140px;
        height: 30px;
        line-height: 30px;
        input {
          width: 100%;
          height: 30px;
          color: #333;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        ::-webkit-input-placeholder {
          color: #333;
        }

        /deep/ .el-input__icon {
          line-height: 30px !important;
        }
      }
    }
  }

  .tongj_box {
    background: #e5f2fe;
    padding: 15px;
    margin: 15px 0;

    h1 {
      font-size: 15px;
      color: #3f74ff;
      font-weight: bold;
      text-align: left;
    }
  }

  .chengse_btn {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: #fe6a00;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    margin: 1% 5px;
    padding: 0 20px;
  }
  .chengse_kong {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    border: 1px solid #fe6a00;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    color: #fe6a00;
    margin: 1% 5px;
    padding: 0 20px;
  }

  //上传图片
  /deep/.el-upload-list--picture {
    width: 100%;
  }
  /deep/.el-upload-list__item {
    width: 33%;
    height: 215px;
    padding: 30px;
    background: #eee;
    border: none;
    float: left;
  }
  /deep/.el-upload-list__item-thumbnail {
    width: 100%;
    height: 168px;
    margin: 0;
  }
  /deep/.el-upload-list__item-name {
    display: none;
  }

  /deep/.el-textarea__inner {
    width: 100%;
    height: 70px;
    padding: 12px;
    border-radius: 0;
  }
  /deep/.el-textarea__inner:focus {
    border-color: #fe6a00;
  }
}
</style>
